<template>
  <div class="list-provider">
    <KTCodePreview v-bind:title="'Danh sách nhà cung cấp'">
      <template v-slot:toolbar>
        <div class="row">
          <router-link to="/providers/add-provider">
            <b-button
              v-show="checkPermission('PROVIDER_INSERT')"
              variant="primary"
              size="sm"
              class="btn btn-primary font-weight-bolder btn-sm"
            >
              <i style="font-size: 1rem" class="flaticon2-add-1"></i>Tạo mới
            </b-button>
          </router-link>
        </div>
      </template>
      <template v-slot:preview>
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-3" style="padding-left: 0">
              <b-form>
                <b-form-group>
                  <b-form-input
                    type="text"
                    size="sm"
                    @input="onFilter"
                    v-model="name"
                    placeholder="Tìm kiếm"
                  ></b-form-input>
                </b-form-group>
              </b-form>
            </div>

            <div class="col-md-3">
              <b-form>
                <b-form-group>
                  <b-form-select
                    v-model="selectedType"
                    :options="typeOptions"
                    v-on:input="onFilter"
                    style="marginleft: auto"
                    size="sm"
                    class="col-md-12 form-control-sm select-style"
                  ></b-form-select>
                </b-form-group>
              </b-form>
            </div>

            <!-- <div class="col-md-3">
              <b-form>
                <b-form-group>
                  <b-form-select
                    v-model="selectedStatus"
                    :options="statusOptions"
                    v-on:input="onFilter"
                    style="marginleft: auto"
                    size="sm"
                    class="col-md-12 form-control-sm select-style"
                  ></b-form-select>
                </b-form-group>
              </b-form>
            </div> -->
          </div>
          <!-- End of Filter session -->
        </div>
        <!-- Table session -->

        <b-table
          :fields="fields"
          :items="providers"
          :busy="onLoading"
          bordered
          hover
        >
          <template v-slot:table-busy>
            <vcl-table :speed="5" :animate="true" :columns="10"></vcl-table>
          </template>
          <template v-slot:cell(count)="row">
            <div style="text-align: center; width: 70px">
              <span v-text="row.item.count" class="text-right"></span>
            </div>
          </template>

          <template v-slot:cell(name)="row">
            <div class="font-weight-bolder" style="width: 180px">
              <span v-text="row.item.name" class="text-right"></span>
            </div>
          </template>

          <template v-slot:cell(phoneNo)="row">
            <div style="text-align: center">
              <span v-text="row.item.phoneNo" class="text-right"></span>
            </div>
          </template>

          <template v-slot:cell(status)="row" style="width: 1%">
            <span
              v-if="checkStatus(row.item)"
              v-text="row.item.status"
              class="label font-weight-bold label-lg label-light-success label-inline"
            ></span>
            <span v-else>
              <span
                v-if="row.item.status"
                class="label font-weight-bold label-lg label-light-danger label-inline"
              >
                {{ row.item.status }}
              </span>
            </span>
          </template>

          <template v-slot:cell(type)="row" style="width: 1%">
            <span v-if="checkType(row.item)">
              <span class="label label-dot label-primary mr-2"></span>
              <span class="font-bold font-primary">{{ row.item.type }}</span>
            </span>
            <span v-else>
              <span
                v-if="row.item.type"
                class="label label-dot label-success mr-2"
              ></span>
              <span class="font-bold font-success">{{ row.item.type }}</span>
            </span>
          </template>

          <template v-slot:cell(actions)="row">
            <div class="d-flex justify-content-center">
              <b-dropdown size="sm" id="dropdown-left" no-caret right>
                <template slot="button-content">
                  <i
                    style="font-size: 1rem; padding-right: 0px"
                    class="flaticon2-settings"
                  ></i>
                </template>
                <b-dropdown-item
                  @click="editItem(row.item)"
                  v-if="!checkViewOnly()"
                >
                  <span style="color: #3f4254; font-size: 12px">
                    <i style="font-size: 1rem" class="flaticon2-pen"></i>
                    &nbsp; Chỉnh sửa
                  </span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="showDeleteAlert(row.item)"
                  v-show="checkPermission('PROVIDER_DELETE')"
                >
                  <span style="color: #3f4254; font-size: 12px">
                    <i
                      style="font-size: 1rem; color: #d33"
                      class="flaticon2-rubbish-bin-delete-button"
                    ></i>
                    &nbsp; Xóa
                  </span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </template>
        </b-table>

        <!-- End of Table session -->
        <!-- Pagination session -->
        <b-row>
          <b-col>
            <p class="mt-3 text-dark" style="font-weight: 500">
              Tổng:
              {{ totalItems }}
            </p>
          </b-col>
          <b-col>
            <b-pagination-nav
              v-show="totalPages >= 2"
              class="custom-pagination"
              :link-gen="linkGen"
              :number-of-pages="totalPages"
              use-router
              @change="fetchProvider"
              align="right"
              first-class="page-item-first btn btn-icon btn-sm m-1"
              prev-class="page-item-prev btn btn-icon btn-sm m-1"
              next-class="page-item-next btn btn-icon btn-sm m-1"
              last-class="page-item-last btn btn-icon btn-sm m-1"
              page-class="btn btn-icon btn-sm border-0 m-1"
            >
              <template v-slot:first-text>
                <span>
                  <i class="ki ki-bold-double-arrow-back icon-xs"></i>
                </span>
              </template>

              <template v-slot:prev-text>
                <i class="ki ki-bold-arrow-back icon-xs"></i>
              </template>

              <template v-slot:next-text>
                <i class="ki ki-bold-arrow-next icon-xs"></i>
              </template>

              <template v-slot:last-text>
                <span class="text-info">
                  <i class="ki ki-bold-double-arrow-next icon-xs"></i>
                </span>
              </template>
            </b-pagination-nav>
          </b-col>
        </b-row>
        <!-- End of Pagination session -->

        <!-- </v-card> -->
      </template>
    </KTCodePreview>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import ApiService from '@/core/services/api.service';
import Swal from 'sweetalert2';
import localData from '../../../utils/saveDataToLocal';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import { VclTable } from 'vue-content-loading';

export default {
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Nhà cung cấp', route: '/providers' },
      { title: 'Danh sách nhà cung cấp', route: '/providers' },
    ]);

    if (this.$route.params.createProviderSuccess) {
      this.makeToastSuccess('Tạo mới nhà cung cấp thành công');
      this.$route.params.createProviderSuccess = false;
    }

    if (this.$route.params.updateProviderSuccess) {
      this.makeToastSuccess(this.$route.params.message);
      this.$route.params.updateProviderSuccess = false;
    }
  },
  data() {
    return {
      onLoading: false,
      currentSort: 'name',
      currentSortDir: 'desc',
      id: '',
      name: '',
      phoneNo: '',
      totalPages: 1,
      count: 1,
      totalItems: 0,
      page: 1,
      currentPage: 1,
      providers: [],
      selectedStatus: null,
      query: '',
      statusOptions: [
        { value: null, text: 'Lọc theo trạng thái' },
        { value: '1', text: 'Đang giao dịch' },
        { value: '2', text: 'Ngưng giao dịch' },
      ],
      selectedType: null,
      typeOptions: [
        { value: null, text: 'Lọc theo loại' },
        { value: 'INDIVIDUAL', text: 'Cá nhân' },
        { value: 'BUSSINESS', text: 'Doanh nghiệp' },
      ],
      fields: [
        {
          key: 'count',
          label: 'STT',
          thStyle: {
            textAlign: 'center',
            fontWeight: 600,
            color: '#181c32',
            width: '6%',
          },
          tdClass: 'sttClass',
        },
        {
          key: 'name',
          label: 'Tên',
          thStyle: { fontWeight: 600, color: '#181c32' },
        },
        {
          key: 'type',
          label: 'Loại',
          thStyle: { fontWeight: 600, color: '#181c32', width: '180px' },
        },
        {
          key: 'phoneNo',
          label: 'Điện thoại',
          thStyle: { fontWeight: 600, color: '#181c32' },
        },
        {
          key: 'status',
          label: 'Trạng thái',
          thStyle: { fontWeight: 600, color: '#181c32' },
        },
        { key: 'actions', label: '' },
      ],
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
    };
  },
  components: {
    KTCodePreview,
    VclTable,
  },
  methods: {
    deleteItem: async function (item) {
      ApiService.patch('providers' + '/' + item.id).then((data) => {
        const { status, message } = data.data;
        if (status === 1) {
          this.makeToastSuccess(message);
          this.fetchProvider();
        } else {
          this.makeToastFaile(message);
        }
      });
    },
    showDeleteAlert: function (item) {
      Swal.fire({
        title: 'Xóa nhà cung cấp!',
        text: 'Bạn có chắc muốn xóa nhà cung cấp này không ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          this.deleteItem(item);
        }
      });
    },
    editItem: function (item) {
      this.$router.push({
        name: 'update-provider',
        query: { id: item.id },
      });
    },

    fetchProvider: async function () {
      this.onLoading = true;
      this.page = this.$route.query.page;
      if (!this.page) {
        this.page = 1;
      }
      //  init params
      const param = {
        page: this.page,
        pageSize: 10,
        id: this.id,
        search: this.name,
        phoneNo: this.phoneNo,
        type: this.selectedType,
        status: this.selectedStatus,
      };

      const paramQuery = {
        params: param,
      };
      ApiService.setHeader();
      ApiService.query('providers', paramQuery)
        .then((response) => {
          this.providers = [];
          this.totalPages =
            response.data.data.paginations.totalPages > 0
              ? response.data.data.paginations.totalPages
              : 1;
          this.totalItems = response.data.data.paginations.total;
          this.currentPage = response.data.data.paginations.currentPage;
          this.providers = response.data.data.data.map((item, index) => {
            return {
              count:
                this.currentPage >= 2
                  ? index + 1 + this.currentPage * 10 - 10
                  : index + 1,
              id: item.id,
              name: item.name,
              phoneNo: item.phoneNumber,
              email: item.email,
              taxNo: item.taxNo,
              type:
                item.type === 'INDIVIDUAL'
                  ? 'Cá nhân'
                  : item.type === null
                  ? ''
                  : 'Doanh nghiệp',
              identityCard: item.identityCard,
              address: item.address,
              bank: item.bank,
              bankBranch: item.bankBranch,
              bankAccountNo: item.bankAccountNo,
              bankAccountName: item.bankAccountName,
              representative: item.representative,
              repPosition: item.repPosition,
              repPhoneNo: item.repPhoneNo,
              status: item.deleted === 0 ? 'Đang giao dịch' : 'Ngưng giao dịch',
            };
          });
          this.onLoading = false;
        })
        .catch(() => {
          this.onLoading = false;
        });
    },
    makeToastSuccess(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'success',
        solid: true,
      });
    },
    makeToastFaile(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'danger',
        solid: true,
      });
    },
    checkStatus: function (item) {
      if (item.status === 'Đang giao dịch') {
        return true;
      } else {
        return false;
      }
    },
    checkType: function (item) {
      if (item.type === 'Cá nhân') {
        return true;
      } else {
        return false;
      }
    },
    checkPermission: function (condition) {
      return localData.checkPermission(condition);
    },
    checkViewOnly: function () {
      let count = 0;
      if (localData.checkPermission('EMPLOYEE_VIEW')) {
        count++;
      }
      if (localData.checkPermission('EMPLOYEE_UPDATE')) {
        count++;
      }
      if (count === 1) {
        return true;
      } else {
        return false;
      }
    },
    searchAutoComplete() {
      this.listResultSearch = this.providers
        .filter((it) =>
          new RegExp(this.itemChoice.name, 'i').test(it.productName),
        )
        .splice(0, 5);
    },
    onFilter() {
      this.$route.query.page = 1;
      this.$router.push({
        name: 'list-provider',
      });
      this.fetchProvider();
    },
  },
  created() {
    this.fetchProvider();
  },
  computed: {},
};
</script>

<style lang="scss">
.list-provider {
  input::-webkit-calendar-picker-indicator {
    display: none;
  }
  .tableStatus {
    width: 1%;
  }

  .sttClass {
    width: 20px;
  }
  .icon:hover {
    background-color: #90c6fc;
  }

  .swal2-cancel:swal2-styled {
    color: black;
  }

  .page-item.disabled {
    cursor: not-allowed;
    &.bv-d-xs-down-none {
      .page-link {
        margin: 0.35rem 0.25rem;
        text-align: center;
      }
    }
  }
}
</style>
